<template>
<div>
     <!-- <Headerpage/> -->
<b-card no-body>
    <b-card-header>
        <b-card-title>{{$t("questregister.questregistertitle")}}</b-card-title>
    </b-card-header>
    <b-card-body>
  <b-form  action="" @submit.prevent="insertQuest">
    <b-row>
      <!-- first name -->
      <b-col  lg="4" md="4" sm="12">
        <b-form-group
          :label="$t('questregister.questtitle')"
        >
          <b-form-input
          v-model="moption.title"
            :placeholder="$t('questregister.questtitle')" required
          />
        </b-form-group>
      </b-col>
       <b-col  lg="4" md="4" sm="12">
        <b-form-group
          :label="$t('questregister.questtitlees')"
        >
          <b-form-input
          v-model="moption.titlees"
            :placeholder="$t('questregister.questtitlees')" required
          />
        </b-form-group>
      </b-col>

      <!-- email -->
      <b-col lg="4" md="4" sm="12">
        <b-form-group
          :label="$t('questregister.imagequest')"
        >
           <b-form-file
      v-model="imagen"
      :placeholder="$t('questregister.imagequest')"
      drop-placeholder="Drop file here..." required
      @input="readURL"
        />
        </b-form-group>
      </b-col>

      <!-- password -->
      <b-col lg="6" md="6" cols="12">
        <b-form-group
          :label="$t('questregister.descriptionquest')"
        >
     <!--      <b-form-textarea
           v-model="moption.description"
      :placeholder="$t('questregister.descriptionquest')"
      rows="3" required
    />
     -->
        <quill-editor
        required
        style="min-height:150px;" 
        v-model="moption.description"
        :placeholder="$t('questregister.descriptionquest')"
        :options="editorOption">
        <div
        id="toolbar"
        slot="toolbar">
        <button class="ql-bold">Bold
        </button>
        <button class="ql-italic">
        Italic
        </button>
        <button class="ql-underline">
        underline
        </button>
        <span class="ql-formats">
        <button class="ql-align" value=""></button>
        <button class="ql-align" value="center"></button>
        <button class="ql-align" value="right"></button>
        <button class="ql-align" value="justify"></button>
        </span>
        <button class="ql-header" value="1">
        H1
        </button>
        <button class="ql-header" value="2">
        H2
        </button>
        <button class="ql-list" value="ordered"/>
        <button class="ql-list" value="bullet"/>
        <button class="ql-indent" value="-1"/>
        <button class="ql-indent" value="+1"/>
        <button class="ql-emoji">
        emoji
        </button>



        </div>
        </quill-editor>
             
        </b-form-group>
      </b-col>
       <b-col lg="6" md="6" cols="12">
        <b-form-group
          :label="$t('questregister.descriptionquestes')"
        > 
      <quill-editor
        required
        style="min-height:150px;" 
        v-model="moption.descriptiones"
        :placeholder="$t('questregister.descriptionquestes')"
        :options="editorOptionTwo">
        <div
        id="toolbar2"
        slot="toolbar">
        <button class="ql-bold">Bold
        </button>
        <button class="ql-italic">
        Italic
        </button>
        <button class="ql-underline">
        underline
        </button>
        <span class="ql-formats">
        <button class="ql-align" value=""></button>
        <button class="ql-align" value="center"></button>
        <button class="ql-align" value="right"></button>
        <button class="ql-align" value="justify"></button>
        </span>
        <button class="ql-header" value="1">
        H1
        </button>
        <button class="ql-header" value="2">
        H2
        </button>
        <button class="ql-list" value="ordered"/>
        <button class="ql-list" value="bullet"/>
        <button class="ql-indent" value="-1"/>
        <button class="ql-indent" value="+1"/>
        <button class="ql-emoji">
        emoji
        </button>



        </div>
        </quill-editor>
        </b-form-group>
      </b-col>
      <b-col md="4" sm="12">
        <b-form-group
        :label="$t('questregister.startdate')">
          <flat-pickr
      v-model="moption.startDate"
        class="form-control"
      required
      :placeholder="$t('questregister.startdate')"
      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
    />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="12">
        <b-form-group
        :label="$t('questregister.duedate')">
          <flat-pickr
      v-model="moption.dueDate"
       class="form-control"
      :placeholder="$t('questregister.duedate')"
      required
      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
    />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="12">
         <b-form-group
         :label="$t('questregister.selecttype')">
        <v-select
          v-model="moption.titleTab"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="option"
          disabled
        />
      </b-form-group>

      </b-col>
      <!-- submit and reset -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
        >
          {{$t('questregister.save')}}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          {{$t('questregister.reset')}}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
  </b-card-body>
  </b-card>

  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BCard,BCardHeader,BCardTitle,BCardBody,BFormFile,
  BFormTextarea,BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SellerService from '@/services/SellerService'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css' 
import "quill-emoji/dist/quill-emoji.css";
import Quill from 'quill'
 import * as Emoji from "quill-emoji";
Quill.register("modules/emoji", Emoji);
import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'


// import Headerpage from "@/components/Headerpage.vue"
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,BCard,BCardHeader,BCardTitle,BCardBody,BFormFile,
    BFormTextarea,BFormDatepicker,vSelect,flatPickr,
  quillEditor
  },
  directives: {
    Ripple,
  },
  data(){
    return{
        moption:{
    "title" : "",
    "titlees":"",
    "description":"",
    "descriptiones": "",
    "startDate" : null,
    "dueDate" : null,
    "status" : true,
    "titleTab" :{ title: 'Featured' } ,
    "items" : [ 
       /* {
            "title" : "Example Item Quest",
            "headerUrl" : "",
            "description" : "",
            "reward" : 5.0,
            "isActive" : true,
            "type" : "openurl"
        }*/
    ]
},
  editorOption: {
modules: {
toolbar: '#toolbar',
  "emoji-toolbar": true,
    "emoji-textarea": true,
    "emoji-shortname": true,
}},
  editorOptionTwo: {
modules: {
toolbar: '#toolbar2',
  "emoji-toolbar": true,
    "emoji-textarea": true,
    "emoji-shortname": true,
}
},
imagen:null,
imagenFile:null,
           option: [{ title: 'Featured' }],
           dialogHidden: true
    }
  },
  methods:{
  toogleDialogEmoji() {
  this.dialogHidden = !this.dialogHidden;
  },
  onSelectEmoji(dataEmoji) {
 
   this.moption.description+= dataEmoji.data;
  this.toogleDialogEmoji();
  },
        readURL: function(input) {
      const vm = this;
      console.log(input);
      if (input) {
        var reader = new FileReader();
        reader.readAsDataURL(input);
        reader.onload = function(e) {
          vm.imagen = e.target.result;
          vm.imagenFile = input;
        };
      }
    },
    insertQuest:async function(){
       const insertar=(await SellerService.insertQuest(JSON.parse(localStorage.getItem("auth")).discordId,
        this.moption,this.imagenFile))
              this.$swal({
          title: "",
          text: "Quest Saved Successfull",
          icon: "success",
           allowOutsideClick: false,
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        }).then(result => {
        if (result.value) {
                this.$router.go(0)
         }
      });
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.ql-container, .ql-editor {
  min-height: inherit;
}
</style>